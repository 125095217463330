class Tile extends Layer
	tiles : []
	grid : 128
	selected_index : null
	snap : (p) ->
		p.x = floor(p.x / @grid) * @grid
		p.y = floor(p.y / @grid) * @grid
		p
	focus : () ->
		for t in @tiles
			if t.type is "stepper"
				t.pos.x = t.start_pos.x
				t.pos.y = t.start_pos.y
	types : 
		static : 
			count : 3
		dynamic :
			count : 9
		stepper :
			count : 4
			max_steps : 4
	random_dir : () ->
		random 0, 3

	setup : (@layers) ->
		@dirs4 = [
			createVector(-1,0)
			createVector(0,-1), 
			createVector(1,0), 
			createVector(0,1), 
		]

		@count = Object.keys(@types).reduce(((a,v,i) => a + @types[v].count), 0)
		@grid = round(@size / 8)

		type_list = Object.keys(@types).reduce(((a,v,i) => a.concat(new Array(@types[v].count).fill(v)) ), [])
		console.log type_list

		i = 0
		while i < @count
			p = createVector random(@size), random(@size)
			t =
				pos : p

			if not @tile_at(t.pos)?
				t.type = type_list[i]
				t.pos = @snap t.pos

				if t.type is "stepper"
					t.rot = i % 4
					# t.rot = floor(Math.random() * 4)
					t.dir = @dirs4[t.rot]
					t.steps = 0
					t.start_pos = createVector t.pos.x, t.pos.y

				# if i < @types.static.count
				# 	t.type = "static"
				# 	# random(Object.keys(@types))
				# else if i < @types.dynamic.count
				# 	t.type = "dynamic"
				# else if i < @types.stepper.count
				# 	t.type = "stepper"
				# 	t.dir = @random_dir()
				@tiles.push t
				i++
	step_tiles : () ->
		s = @grid
		for t in @tiles
			if t.type is "stepper"
				t.steps += 1
				if t.steps is @types.stepper.max_steps
					t.steps = 0
					t.pos.x = t.start_pos.x
					t.pos.y = t.start_pos.y
				else
					t.pos.add createVector(t.dir.x * s, t.dir.y * s)

	draw : (delta = 0, time = 0, external = false) ->
		if not external
			@image.clear()
			# @image.background Colors.white

		@image.noStroke()
		@image.fill Colors.red
		@image.strokeWeight 8
		for t, i in @tiles

			if @selected_index? and @selected_index is i
				@image.noStroke()
				selected_tile = @tiles[@selected_index]
				@image.fill "#00000055"
				@image.rect t.pos.x, t.pos.y, @grid, @grid
				@image.fill Colors.red
				@image.rect selected_tile.pos.x, selected_tile.pos.y - 16, @grid, @grid
			else
				# @image.fill Colors.red
				name = ""

				switch t.type
					when "static"
						@image.noStroke()
						@image.fill Colors.black
						@image.rect t.pos.x, t.pos.y, @grid, @grid
						@image.noFill()
						@image.stroke Colors.red
						@image.strokeWeight @grid * 0.125
						@image.line t.pos.x + @grid * 0.25, t.pos.y + @grid * 0.25, t.pos.x  + @grid * 0.75, t.pos.y + @grid * 0.75
						@image.line t.pos.x + @grid * 0.75, t.pos.y + @grid * 0.25, t.pos.x  + @grid * 0.25, t.pos.y + @grid * 0.75
					when "dynamic"
						@image.noStroke()
						@image.fill Colors.red
						@image.rect t.pos.x, t.pos.y, @grid, @grid
					when "stepper"
						@image.noStroke()
						@image.fill "#E86E2477"
						# @image.fill Colors.red
						for j in [0..@types.stepper.max_steps - 1]
							p = createVector t.start_pos.x + j * @grid * t.dir.x, t.start_pos.y + j * @grid * t.dir.y
							@image.rect p.x, p.y, @grid, @grid




						@image.noFill()
						@image.push()
						g = @grid * 0.5
						g2 = @grid * 0.25
						g3 = g2 * 0.5
						@image.translate t.pos.x + @grid * 0.5, t.pos.y + @grid * 0.5, 1 + (i % @types.stepper.count) * 0.01
						@image.fill Colors.red
						@image.noStroke()
						@image.rect -g, -g, @grid, @grid

						@image.stroke Colors.black
						@image.strokeWeight @grid * 0.125
						@image.rotate( Math.PI * t.rot * 0.5)
						@image.line -g2,0,g2, g2
						@image.line -g2,0,g2, -g2
						# @image.line t.pos.x + @grid * 0.25, t.pos.y + @grid * 0.25, t.pos.x  + @grid * 0.75, t.pos.y + @grid * 0.75
						# @image.line t.pos.x + @grid * 0.75, t.pos.y + @grid * 0.25, t.pos.x  + @grid * 0.25, t.pos.y + @grid * 0.75
						@image.pop()
					# @image.noStroke()



	tile_at : (p) ->
		for t, i in @tiles
			if collidePointRect p.x, p.y, t.pos.x, t.pos.y, @grid, @grid
				return i
		null

	select : (p) ->
		p = @pixel p
		@selected_index = @tile_at p
		if @selected_index?
			if @tiles[@selected_index].type is "static"
				@selected_index = null

	press : (p) ->
		@select p
		@draw()
	pressup : (p) ->
		@selected_index = null
		@draw()
	panstart : (p) ->
		@select p
		@draw()
	panend : (p) ->
		@selected_index = null
		@draw()
	pan : (p) ->
		p.x = constrain p.x, 0, 0.99
		p.y = constrain p.y, 0, 0.99
		if @selected_index?
			target_pos = @pixel p
			if not @tile_at(target_pos)?
				pos = @snap target_pos
				@tiles[@selected_index].pos = pos
				if @tiles[@selected_index].type is "stepper"
					@tiles[@selected_index].start_pos = createVector pos.x, pos.y
		@draw()
		# console.log p
	collide_pos : (p) ->
		for t in @tiles
			if @tile_at(p)?
				return true
		false
